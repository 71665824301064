<template>
  <div class="doc-container">
    <div class="doc-container__left mr-10">
      <lz-tree
        :list="list"
        ref="lzTree"
        :accordion="false"
        @nodeClick="handleNodeClick"
        :props="{label:'title'}"
        :default-expand-all="true"
        :highlight-current="true">
      </lz-tree>
    </div>
    <div class="doc-container__right">
      <template v-if="id != ''">
        <div v-if="content != ''" class="content" v-html="content"></div>
        <div v-else class="empty-content"></div>
      </template>
      <div class="empty" v-if="id == ''"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      content: '',
      id: '',
    };
  },
  watch: {
    $route: function change() {
      this.getData();
    },
  },
  mounted() {
    this.$baseHttp.get('/auth-rule/menu').then((response) => {
      this.list = response.data;
      const id = this.$route.query.id;
      if (id) {
        this.$nextTick(() => {
          this.$refs.lzTree.setCurrentKey(id);
          this.handleNodeClick({ id });
          this.getData();
        });
      }
    });
  },
  methods: {
    async handleNodeClick(element) {
      this.id = element.id;
      this.$router.push({
        name: 'doc',
        query: { id: this.id },
      });
    },
    getData() {
      this.content = '';
      this.$baseHttp.get(`/auth-article/detail?menu_id=${this.id}`).then((response) => {
        if (!response) return;
        this.content = response.data.content;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.doc-container {
  height: 100vh;
  display: flex;
  .doc-container__left,
  .doc-container__right {
    background: #fff;
    border: 1px solid #EBEEF5;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    box-sizing: border-box;
  }
  .doc-container__left {
    width: 300px;
    font-size: 14px;
    color: #666;
    ::v-deep .el-tree {
      width: 100%;
    }
  }
  .doc-container__right {
    width: calc(100% - 320px);
    padding: 20px;
    position: relative;
    height: 100%;
    overflow: hidden;
    .content {
      overflow-y: auto;
    }
  }
}
.empty:after {
  position: absolute;
  top: 50%;
  color: #ccc;
  content: "礼一在线帮助文档";
  text-align: center;
  font-size: 20px;
  width: 100%;
}
.empty-content:after {
  position: absolute;
  top: 50%;
  color: #ccc;
  content: "暂无内容";
  text-align: center;
  font-size: 20px;
  width: 100%;
}
</style>
